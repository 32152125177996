import { TSelectItem, TSelectItems } from '@sim-admin-frontends/ui-shared';
import {
  ChatGptKnowledgeBase,
  ImageInput,
  MessagingProblemConnectorType,
  PaymentReceiver,
  PlaceFeatureFlag,
  PlateCheckerConnector,
  TSAPlaceDetailNoNull,
  TicketmasterCountryCode,
} from '@sim-admin-frontends/data-access-admin-be';
import {
  CountryCode,
  InstitutionFeatureFlag,
  TSATInstitutionInfoNoNull,
} from '@sim-admin-frontends/data-access';
import { SuppportedLanguages } from '@sim-admin-frontends/utils-shared';

export type TPayParkingSettings = {
  connectorTypes?: TSelectItems;
};

export type PaymentReceiverOption = {
  label: PaymentReceiver;
  value: PaymentReceiver;
};

export type TPlateCheckerConnectorOption = {
  label: PlateCheckerConnector;
  value: PlateCheckerConnector;
};

export type TPlateCheckerSettingsValues = {
  connector: TPlateCheckerConnectorOption;
};

export type TChatGptKnowledgeBaseSelectItem = {
  label: ChatGptKnowledgeBase;
  value: ChatGptKnowledgeBase;
};

export type TChatBotSettingsValues = {
  topics?: TSelectItems;
  knowledgeBase?: TChatGptKnowledgeBaseSelectItem;
};

export type TMessagingProblemConnectorItem = {
  label: MessagingProblemConnectorType;
  value: MessagingProblemConnectorType;
};

export type TMessagingProblemSettings = {
  connectorTypes?: TMessagingProblemConnectorItem[];
  isLaneAvailable?: boolean | null;
  showCategories?: boolean | null;
  isMediaRequired?: boolean | null;
  potholeCategory?: TSelectItem;
};

export type TTitleLocalizations = { [T in SuppportedLanguages]?: string };

export type TCountryCodeOption = {
  label: CountryCode;
  value: CountryCode;
};

export type TTicketmasterSettingsValue = {
  placeName?: string;
  stateCode?: string;
  countryCode?: {
    label: TicketmasterCountryCode;
    value: TicketmasterCountryCode;
  };
  institution?: TSelectItem;
};

export type OmittedPlaceFeatureFlag = Omit<
  PlaceFeatureFlag,
  | 'icon'
  | 'reminderSettings'
  | 'type'
  | 'allowedPaymentReceivers'
  | 'messagingProblemCategoryUuid'
  | 'messagingProblemIsLaneAvailable'
  | 'reportedProblemsSettings'
  | 'chatBotSettings'
  | 'plateCheckerSettings'
  | 'payParkingSettings'
  | 'titleLocalizations'
  | 'tuiCountryCode'
  | 'ticketmasterSettings'
>;

export type TPlaceFeatureFlagValues = OmittedPlaceFeatureFlag & {
  icon?: (File | ImageInput)[] | null;
  type?: TSelectItem;
  allowedPaymentReceivers?: PaymentReceiverOption[] | null;
  builtInType?: TSelectItem;
  messagingProblemSettings?: TMessagingProblemSettings | null;
  chatBotSettings?: TChatBotSettingsValues;
  plateCheckerSettings?: TPlateCheckerSettingsValues;
  payParkingSettings?: TPayParkingSettings;
  titleLocalizations?: TTitleLocalizations;
  tuiCountryCode?: TCountryCodeOption;
  ticketmasterSettings?: TTicketmasterSettingsValue;
};

export enum TBuiltInFeatureFlagTypes {
  COUPON = 'Coupon',
  FOR_YOU_TAB = 'ForYouTab',
  REPORT_PROBLEM = 'ReportAProblem',
  SIMPLICITY_ID = 'SimplicityID',
  TRANSPORT = 'Transport',
  Itinerary = 'Itinerary',

  // deprecated
  ANY_PAYMENTS = 'AnyPayments',
  DELIGHTED_SDK = 'Delighted.com',
}

export type TInstitutionFeatureFlagValues = Omit<
  InstitutionFeatureFlag,
  'icon' | 'type' | 'titleLocalizations'
> & {
  icon?: (File | ImageInput)[] | null;
  type: TSelectItem;
  titleLocalizations?: TTitleLocalizations;
};

export type TFeatureFlagValues = TPlaceFeatureFlagValues | TInstitutionFeatureFlagValues;

export type TFeatureFlag = PlaceFeatureFlag | InstitutionFeatureFlag;

export type TFeatureFlags = PlaceFeatureFlag[] | InstitutionFeatureFlag[];

export type TFeatureFlagsParent = TSAPlaceDetailNoNull | TSATInstitutionInfoNoNull;

export enum FeatureFlagsMenuItem {
  PET_DIRECTORY = 'Pet Directory',
  MESSAGING = 'Messaging',
  COMMUNITY_FUND = 'Community Fund',
  COUPON = 'Coupon',
  SIMPLICITY_ID = 'SimplicityID',
  SMART_FOLLOWS = 'Smart Follows',
  ASK_MAYOR_QUESTION = 'AMQ (Ask Mayor a Question (by video))',
  ANY_PAYMENTS = 'AnyPayments',
  TRANSPORT = 'Transport',
  GARBAGE = 'Garbage',
  DELIGHTED_SDK = 'Delighted.com',
  FOR_YOU_TAB = 'ForYouTab',
  REPORT_PROBLEM = 'ReportAProblem',
  CHATBOT = 'Chatbot',
}

export type TDefaultLanguageSettings = {
  countryCode?: string;
  language?: string;
};
