import { CountryCode, TUploadedFile } from '@sim-admin-frontends/data-access';
import {
  FeatureFlagInternalType,
  PlaceFeatureFlag,
  PlaceFeatureFlagInput,
  PlaceInput,
  TSAPlaceDetailNoNull,
  ChatGptTopic,
} from '@sim-admin-frontends/data-access-admin-be';
import { TSelectItems } from '@sim-admin-frontends/ui-shared';
import { prepareFileObjects, omitObjectProperty } from '@sim-admin-frontends/utils-shared';

import {
  TChatBotSettingsValues,
  TMessagingProblemConnectorItem,
  TPlaceFeatureFlagValues,
} from '../types/TFeatureFlags';
import { cloneFlagWithoutIcon, transformTitleLocalizationsForBE } from './featureFlagsUtils';
import { TMessagingCategory } from '../types/TReportProblemCategories';

export const appendValuesFields = (
  values: TPlaceFeatureFlagValues,
  iconImage?: TUploadedFile[],
) => {
  const placeFeatureFlagInput = transformPlaceFeatureFlagforBE(values);

  return {
    ...placeFeatureFlagInput,
    icon: prepareFileObjects(iconImage)[0],
  };
};

const parseNumber = (value: any) => {
  const parsedValue = parseInt(value);
  return Number.isNaN(parsedValue) ? undefined : parsedValue;
};

export const transformPlaceFeatureFlagforBE = (
  values: TPlaceFeatureFlagValues,
): PlaceFeatureFlagInput => ({
  title: values.title,
  description: values.description,
  url: values.url,
  enabled: values.enabled,
  order: Number(values.order),
  delightedSettings: values.delightedSettings,
  type: values.type?.value as FeatureFlagInternalType,
  allowedPaymentReceivers: values.allowedPaymentReceivers?.map((item) => item.value),
  reportedProblemsSettings: {
    isLaneAvailable: values.messagingProblemSettings?.isLaneAvailable,
    connectorTypes: values.messagingProblemSettings?.connectorTypes?.map((type) => type.value),
    showCategories: values.messagingProblemSettings?.showCategories,
    isMediaRequired: values.messagingProblemSettings?.isMediaRequired,
    potholeCategoryUuid: values.messagingProblemSettings?.potholeCategory?.value,
  },
  chatBotSettings: values.chatBotSettings?.topics
    ? { topics: values.chatBotSettings.topics?.map((topic) => topic.value as ChatGptTopic) }
    : undefined,
  plateCheckerSettings: values.plateCheckerSettings
    ? {
        connector: values.plateCheckerSettings.connector.value,
      }
    : undefined,
  isFullnameRequired: values.isFullnameRequired,
  disableProblemsOnMap: values.disableProblemsOnMap,
  payParkingSettings: values.payParkingSettings
    ? { connectorTypes: values.payParkingSettings?.connectorTypes?.map((item) => item.value) }
    : undefined,
  titleLocalizations: values.titleLocalizations
    ? transformTitleLocalizationsForBE(values.titleLocalizations)
    : undefined,
  visitorMode: values.visitorMode ?? false,
  tuiCityId: parseNumber(values.tuiCityId),
  tuiCountryCode: values.tuiCountryCode?.value,
  ticketmasterSettings: values.ticketmasterSettings
    ? {
        placeName: values.ticketmasterSettings.placeName,
        countryCode: values.ticketmasterSettings.countryCode?.value,
        stateCode: values.ticketmasterSettings.stateCode,
        institutionUuid: values.ticketmasterSettings.institution?.value,
      }
    : undefined,
  theInfatuationPlaceId: values.theInfatuationPlaceId,
  chatContextEnabled: values.chatContextEnabled,
  isSystem: values.isSystem ?? false,
  viatorDestination: values.viatorDestination,
});

export const omitPlace = (place: TSAPlaceDetailNoNull) =>
  omitObjectProperty(place, [
    'createdAt',
    'branding',
    'enabled',
    'isDistrict',
    'sectionImages',
    'badgesImage',
    'welcomeScreenImage',
    'mapIntroImage',
    'dashboardImages',
  ]) as PlaceInput;

export const transformFeatureFlagsValues = (
  parentObject: TSAPlaceDetailNoNull,
  featureFlags: PlaceFeatureFlag[],
  values: TPlaceFeatureFlagValues,
  iconImage?: TUploadedFile[],
  featureIndex?: string,
) => {
  const newPlace = omitPlace(parentObject);
  if (featureIndex) {
    const prevFlags: PlaceFeatureFlag[] = featureFlags.map((flag, index) => {
      if (index === Number(featureIndex)) {
        return appendValuesFields(values, iconImage);
      }
      return { ...cloneFlagWithoutIcon(flag) };
    });

    return { newFlag: null, prevFlags, placeInfo: newPlace };
  }
  const newFlag: PlaceFeatureFlag = appendValuesFields(values, iconImage);
  const prevFlags: PlaceFeatureFlag[] = featureFlags.map((flag) => cloneFlagWithoutIcon(flag));
  return { newFlag, prevFlags, placeInfo: newPlace };
};

export const compareFlagOrder = (a: PlaceFeatureFlag, b: PlaceFeatureFlag) => {
  const aOrder = a.order;
  const bOrder = b.order;
  if ((aOrder ?? 0) < (bOrder ?? 0)) {
    return -1;
  }
  if ((aOrder ?? 0) > (bOrder ?? 0)) {
    return 1;
  }
  return 0;
};

export const getPlaceFeatureFlagType = (featureFlag?: PlaceFeatureFlag) => {
  const isDelighted = !!featureFlag?.delightedSettings?.delightedID;
  const isAnyPayments = !!featureFlag?.allowedPaymentReceivers?.length;

  if (isDelighted) {
    return { label: FeatureFlagInternalType.Delighted, value: FeatureFlagInternalType.Delighted };
  }
  if (isAnyPayments) {
    return {
      label: FeatureFlagInternalType.AnyPayments,
      value: FeatureFlagInternalType.AnyPayments,
    };
  }

  return featureFlag?.type ? { label: featureFlag?.type, value: featureFlag?.type } : undefined;
};

export const getInitialChatbotSettings = (
  featureFlag?: PlaceFeatureFlag,
): TChatBotSettingsValues | undefined => {
  if (!featureFlag?.chatBotSettings) {
    return undefined;
  }

  const chatBotSettings = featureFlag.chatBotSettings;
  const initialChatbotSettings = {
    topics: chatBotSettings.topics
      ? chatBotSettings.topics.map((topic) => ({
          label: topic || '',
          value: topic || '',
        }))
      : undefined,
    knowledgeBase: chatBotSettings.knowledgeBase
      ? { label: chatBotSettings.knowledgeBase, value: chatBotSettings.knowledgeBase }
      : undefined,
  };

  return initialChatbotSettings;
};

export const getInitialPlateCheckerSettings = (featureFlag?: PlaceFeatureFlag) => {
  if (!featureFlag?.plateCheckerSettings?.connector) {
    return undefined;
  }

  return {
    connector: {
      label: featureFlag?.plateCheckerSettings.connector,
      value: featureFlag?.plateCheckerSettings.connector,
    },
  };
};

export const getInitialTUICountryCode = (featureFlag?: PlaceFeatureFlag) => {
  if (!featureFlag?.tuiCountryCode) {
    return undefined;
  }

  return {
    label: featureFlag?.tuiCountryCode as CountryCode,
    value: featureFlag?.tuiCountryCode as CountryCode,
  };
};

export const getInitialMessagingProblemSettings = (
  featureFlag?: PlaceFeatureFlag,
  defaultPotholeCategory?: TMessagingCategory,
) => {
  if (!featureFlag?.reportedProblemsSettings) {
    return undefined;
  }

  return {
    isLaneAvailable: featureFlag?.reportedProblemsSettings.isLaneAvailable,
    connectorTypes:
      featureFlag?.reportedProblemsSettings.connectorTypes?.reduce<
        TMessagingProblemConnectorItem[]
      >((acc, item) => {
        if (!item) {
          return acc;
        }

        return [...acc, { label: item, value: item }];
      }, []) || undefined,
    showCategories: featureFlag.reportedProblemsSettings.showCategories,
    isMediaRequired: featureFlag.reportedProblemsSettings.isMediaRequired,
    potholeCategory: defaultPotholeCategory
      ? { label: defaultPotholeCategory.name, value: defaultPotholeCategory.id }
      : undefined,
  };
};

export const getInitialPayParkingSettings = (featureFlag?: PlaceFeatureFlag) => {
  if (!featureFlag?.payParkingSettings) {
    return undefined;
  }

  return {
    connectorTypes: featureFlag.payParkingSettings.connectorTypes?.reduce<TSelectItems>(
      (acc, item) => {
        if (!item) {
          return acc;
        }
        return [...acc, { label: item, value: item }];
      },
      [],
    ),
  };
};

export const getInitialTicketmasterSettings = (
  featureFlag?: PlaceFeatureFlag,
  institutionName?: string,
) => {
  if (!featureFlag?.ticketmasterSettings) {
    return undefined;
  }

  return {
    placeName: featureFlag?.ticketmasterSettings.placeName || undefined,
    countryCode: featureFlag?.ticketmasterSettings.countryCode
      ? {
          value: featureFlag?.ticketmasterSettings.countryCode,
          label: featureFlag?.ticketmasterSettings.countryCode,
        }
      : undefined,
    stateCode: featureFlag?.ticketmasterSettings.stateCode || undefined,
    institution:
      featureFlag?.ticketmasterSettings?.institutionUuid && institutionName
        ? {
            value: featureFlag?.ticketmasterSettings?.institutionUuid,
            label: institutionName,
          }
        : undefined,
  };
};
