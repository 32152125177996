import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Table, TableActionHeader } from '@sim-admin-frontends/ui-shared';
import { PlaceFeatureFlag } from '@sim-admin-frontends/data-access-admin-be';
import { generatePath, Link } from 'react-router-dom';

import ROUTES from '../../../routing/routes';
import { createFeatureFlagsTableColumns } from './helper';
import { Container, SubmitContainer } from '../../common/featureFlags/FeatureFlagFormStyles';

type Props = {
  onSubmit: (values: PlaceFeatureFlag[]) => Promise<void>;
  initialValues: PlaceFeatureFlag[];
  isLoading: boolean;
  placeId: string;
};

const PlaceFeatures: FC<Props> = ({ onSubmit, initialValues, isLoading, placeId }) => {
  const { t } = useTranslation();
  const [dataToTable, setDataToTable] = useState(initialValues);

  useEffect(() => {
    setDataToTable(initialValues);
  }, [initialValues]);

  const onToggle = (index: number) => {
    const newData = dataToTable;
    newData[index].enabled = !dataToTable[index].enabled;
    setDataToTable(newData);
  };

  const onDelete = (index: number) => {
    const newData = dataToTable.filter((_, i) => index !== i);
    setDataToTable(newData);
  };

  const tableColumns = createFeatureFlagsTableColumns(onToggle, onDelete, placeId);

  const onSubmitClick = async () => {
    await onSubmit(dataToTable);
  };

  return (
    <Container>
      <TableActionHeader>
        <Link to={generatePath(ROUTES.placeFeaturesEdit.path, { placeId })}>
          <Button testId="PlacesTabs#CreateFlag">{t('places.features.create')}</Button>
        </Link>
      </TableActionHeader>
      <Table
        loading={isLoading}
        itemsCount={dataToTable.length}
        pageIndex={0}
        data={dataToTable}
        columns={tableColumns}
        hasPagination={false}
        onNextPress={() => undefined}
        onPreviousPress={() => undefined}
        testId="PlacesFeatureFlags#Table"
      />
      <SubmitContainer>
        <Button onClick={onSubmitClick} testId="PlacesTabs#SaveChange">
          {t('places.features.saveChanges')}
        </Button>
      </SubmitContainer>
    </Container>
  );
};

export default PlaceFeatures;
